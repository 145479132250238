import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
	meta: {
		requireAuth: false,
	},
    component: Login
  },
  {
    path: '/main',
    name: 'Main',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/MainTab.vue')
  },
  {
    path: '/home',
    name: 'Home',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/Home.vue')
  },
  {
    path: '/forgetPwd',
    name: 'ForgetPwd',
	meta: {
		requireAuth: false,
	},
    component:() => import('../views/login/ForgetPwd.vue')
  },
 //  {
 //    path: '/register',
 //    name: 'Register',
	// meta: {
	// 	requireAuth: false,
	// },
 //    component:() => import('../views/login/Register.vue')
 //  },
  {
    path: '/invite',
    name: 'Invite',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/Invite.vue')
  },
  {
    path: '/withdraw',
    name: 'Withdraw',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/Withdraw.vue')
  },
  {
    path: '/withdrawUsdt',
    name: 'WithdrawUsdt',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/withdraw/WithdrawUsdt.vue')
  },
  {
    path: '/withdrawWdt',
    name: 'WithdrawWdt',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/withdraw/WithdrawWdt.vue')
  },
  {
    path: '/withdrawPbt',
    name: 'WithdrawPbt',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/withdraw/WithdrawPbt.vue')
  },
  {
    path: '/recharge',
    name: 'Recharge',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/Recharge.vue')
  },
  {
    path: '/rechargeUsdt',
    name: 'RechargeUsdt',
	meta: {
		requireAuth: true,
	},
	component:() => import('../views/home/recharge/RechargeUsdt.vue')
  },
  {
    path: '/rechargeWdt',
    name: 'RechargeWdt',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/recharge/RechargeWdt.vue')
  },
  {
    path: '/rechargePbt',
    name: 'RechargePbt',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/recharge/RechargePbt.vue')
  },
  {
    path: '/notice',
    name: 'Notice',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/Notice.vue')
  },
  {
    path: '/buy',
    name: 'Buy',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/Buy.vue')
  },
  {
    path: '/buyPbt',
    name: 'BuyPbt',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/buy_usdt/pbt/BuyPbt.vue')
  },
  {
    path: '/buyPbtForSelf',
    name: 'BuyPbtForSelf',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/buy_usdt/pbt/BuyPbtForSelf.vue')
  },
  {
    path: '/buyPbtForOther',
    name: 'BuyPbtForOther',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/buy_usdt/pbt/BuyPbtForOther.vue')
  },
  
  {
    path: '/buyWdt',
    name: 'BuyWdt',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/buy_usdt/wdt/BuyWdt.vue')
  },
  {
    path: '/buyWdtForSelf',
    name: 'BuyWdtForSelf',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/buy_usdt/wdt/BuyWdtForSelf.vue')
  },
  {
    path: '/buyWdtForOther',
    name: 'BuyWdtForOther',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/buy_usdt/wdt/BuyWdtForOther.vue')
  },
  {
    path: '/buyBitMall',
    name: 'BuyBitMall',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/buy_usdt/bitMall/BuyBitMall.vue')
  },
  {
    path: '/buyBitMallForSelf',
    name: 'BuyBitMallForSelf',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/buy_usdt/bitMall/BuyBitMallForSelf.vue')
  },
  {
    path: '/buyBitMallForOther',
    name: 'BuyBitMallForOther',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/buy_usdt/bitMall/BuyBitMallForOther.vue')
  },
  
  {
    path: '/buyHK',
    name: 'BuyHongKong',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/buy_usdt/hk/BuyHongKong.vue')
  },
  {
    path: '/buyHkForSelf',
    name: 'BuyHkForSelf',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/buy_usdt/hk/BuyHkForSelf.vue')
  },
  {
    path: '/buyHkForOther',
    name: 'BuyHkForOther',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/buy_usdt/hk/BuyHkForOther.vue')
  },
  {
    path: '/buyNasdaq',
    name: 'BuyNasdaq',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/buy_usdt/nasdaq/BuyNasdaq.vue')
  },
  {
    path: '/buyNasdaqForSelf',
    name: 'BuyNasdaqForSelf',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/buy_usdt/nasdaq/BuyNasdaqForSelf.vue')
  },
  {
    path: '/buyNasdaqForOther',
    name: 'BuyNasdaqForOther',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/buy_usdt/nasdaq/BuyNasdaqForOther.vue')
  },
  {
	path: '/buyAmbc',
	name: 'BuyAmbc',
	meta: {
		requireAuth: true,
	},
	component:() => import('../views/home/buy_usdt/ambc/BuyAmbc.vue')
  },
  {
    path: '/buyAmbcForSelf',
    name: 'BuyAmbcForSelf',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/buy_usdt/ambc/BuyAmbcForSelf.vue')
  },
  {
    path: '/buyAmbcForOther',
    name: 'BuyAmbcForOther',
	meta: {
		requireAuth: true,
	},
	component:() => import('../views/home/buy_usdt/ambc/BuyAmbcForOther.vue')
  },
  {
	path: '/buyAmbg',
	name: 'BuyAmbg',
	meta: {
		requireAuth: true,
	},
	component:() => import('../views/home/buy_usdt/ambg/BuyAmbg.vue')
  },
  {
	path: '/buyAmbgForSelf',
	name: 'BuyAmbgForSelf',
	meta: {
		requireAuth: true,
	},
	component:() => import('../views/home/buy_usdt/ambg/BuyAmbgForSelf.vue')
  },
  {
	path: '/buyAmbgForOther',
	name: 'BuyAmbgForOther',
	meta: {
		requireAuth: true,
	},
	component:() => import('../views/home/buy_usdt/ambg/BuyAmbgForOther.vue')
  },
  {
	path: '/buyPbtWithUsdm',
	name: 'BuyPbtWithUsdm',
	meta: {
		requireAuth: true,
	},
	component:() => import('../views/home/buy_usdm/pbt/BuyPbt.vue')
  },
  {
	path: '/buyPbtForSelfWithUsdm',
	name: 'BuyPbtForSelfWithUsdm',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/buy_usdm/pbt/BuyPbtForSelf.vue')
  },
  {
	path: '/buyPbtForOtherWithUsdm',
	name: 'BuyPbtForOtherWithUsdm',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/buy_usdm/pbt/BuyPbtForOther.vue')
  },
  {
	path: '/buyWdtWithUsdm',
	name: 'BuyWdtWithUsdm',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/buy_usdm/wdt/BuyWdt.vue')
  },
  {
	path: '/buyWdtForSelfWithUsdm',
    name: 'BuyWdtForSelfWithUsdm',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/buy_usdm/wdt/BuyWdtForSelf.vue')
  },
  {
	path: '/buyWdtForOtherWithUsdm',
    name: 'BuyWdtForOtherWithUsdm',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/buy_usdm/wdt/BuyWdtForOther.vue')
  },
  {
	path: '/buyHKWithUsdm',
	name: 'BuyHongKongWithUsdm',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/buy_usdm/hk/BuyHongKong.vue')
  },
  {
    path: '/buyHkForSelfWithUsdm',
    name: 'BuyHkForSelfWithUsdm',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/buy_usdm/hk/BuyHkForSelf.vue')
  },
  {
    path: '/buyHkForOtherWithUsdm',
    name: 'BuyHkForOtherWithUsdm',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/buy_usdm/hk/BuyHkForOther.vue')
  },
  {
    path: '/buyNasdaqWithUsdm',
    name: 'BuyNasdaqWithUsdm',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/buy_usdm/nasdaq/BuyNasdaq.vue')
  },
  {
    path: '/buyNasdaqForSelfWithUsdm',
    name: 'BuyNasdaqForSelfWithUsdm',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/buy_usdm/nasdaq/BuyNasdaqForSelf.vue')
  },
  {
    path: '/buyNasdaqForOtherWithUsdm',
    name: 'BuyNasdaqForOtherWithUsdm',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/buy_usdm/nasdaq/BuyNasdaqForOther.vue')
  },
  {
	path: '/buyAmbcWithUsdm',
	name: 'BuyAmbcWithUsdm',
	meta: {
		requireAuth: true,
	},
	component:() => import('../views/home/buy_usdm/ambc/BuyAmbc.vue')
  },
  {
    path: '/buyAmbcForSelfWithUsdm',
    name: 'BuyAmbcForSelfWithUsdm',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/home/buy_usdm/ambc/BuyAmbcForSelf.vue')
  },
  {
    path: '/buyAmbcForOtherWithUsdm',
    name: 'BuyAmbcForOtherWithUsdm',
	meta: {
		requireAuth: true,
	},
	component:() => import('../views/home/buy_usdm/ambc/BuyAmbcForOther.vue')
  },
  {
	path: '/buyAmbgWithUsdm',
	name: 'BuyAmbgWithUsdm',
	meta: {
		requireAuth: true,
	},
	component:() => import('../views/home/buy_usdm/ambg/BuyAmbg.vue')
  },
  {
	path: '/buyAmbgForSelfWithUsdm',
	name: 'BuyAmbgForSelfWithUsdm',
	meta: {
		requireAuth: true,
	},
	component:() => import('../views/home/buy_usdm/ambg/BuyAmbgForSelf.vue')
  },
  {
	path: '/buyAmbgForOtherWithUsdm',
	name: 'BuyAmbgForOtherWithUsdm',
	meta: {
		requireAuth: true,
	},
	component:() => import('../views/home/buy_usdm/ambg/BuyAmbgForOther.vue')
  },
  {
    path: '/accountSetting',
    name: 'AccountSetting',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/setting/AccountSetting.vue')
  },
  {
    path: '/authStatus',
    name: 'AuthStatus',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/setting/auth/AuthStatus.vue')
  },
  {
    path: '/auth',
    name: 'Auth',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/setting/auth/Auth.vue')
  },
  {
    path: '/addressList',
    name: 'AddressList',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/setting/address/AddressList.vue')
  },
  {
    path: '/addressAdd',
    name: 'AddressAdd',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/setting/address/AddressAdd.vue')
  },
  {
    path: '/changeLoginPwd',
    name: 'ChangeLoginPwd',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/setting/pwd/ChangeLoginPwd.vue')
  },
  {
    path: '/changePayPwd',
    name: 'ChangePayPwd',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/setting/pwd/ChangePayPwd.vue')
  },
  {
    path: '/transfer',
    name: 'Transfer',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/fund/transfer/Transfer.vue')
  },
  {
    path: '/transferUsdt',
    name: 'TransferUsdt',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/fund/transfer/TransferUsdt.vue')
  },
  {
    path: '/transferUsdm',
    name: 'TransferUsdm',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/fund/transfer/TransferUsdm.vue')
  },
  {
    path: '/transferNasdaq',
    name: 'TransferNasdaq',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/fund/transfer/TransferNasdaq.vue')
  },
  {
    path: '/transferHk',
    name: 'TransferHk',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/fund/transfer/TransferHk.vue')
  },
  {
    path: '/transferWdt',
    name: 'TransferWdt',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/fund/transfer/TransferWdt.vue')
  },
  {
    path: '/transferPbt',
    name: 'TransferPbt',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/fund/transfer/TransferPbt.vue')
  },
  {
    path: '/transferAmbg',
    name: 'TransferAmbg',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/fund/transfer/TransferAmbg.vue')
  },
  {
    path: '/transferAmbc',
    name: 'TransferAmbc',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/fund/transfer/TransferAmbc.vue')
  },
  {
    path: '/history',
    name: 'History',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/fund/history/History.vue')
  },
  {
    path: '/mall',
    name: 'GoodsList',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/mall/GoodsList.vue')
  },
  {
    path: '/detail',
    name: 'GoodsDetail',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/mall/GoodsDetail.vue')
  },
  {
    path: '/success',
    name: 'BuySuccess',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/mall/BuySuccess.vue')
  },
  {
    path: '/orderList',
    name: 'OrderList',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/mall/OrderList.vue')
  },
  {
    path: '/orderDetail',
    name: 'OrderDetail',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/mall/OrderDetail.vue')
  },
  {
    path: '/backup',
    name: 'BackUp',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/setting/google/Backup.vue')
  },
  {
    path: '/backupVerify',
    name: 'BackupVerification',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/setting/google/BackupVerification.vue')
  },
  {
    path: '/codeVerify',
    name: 'CodeVerification',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/setting/google/CodeVerification.vue')
  },
  {
    path: '/cartList',
    name: 'CartList',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/mall/CartList.vue')
  },
  {
    path: '/moneyIndex',
	name: 'Money',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/money/index.vue')
  },
  {
    path: '/moneyDetail',
    name: 'MoneyDetail',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/money/detail.vue')
  },
  {
    path: '/myFundList',
    name: 'MyFundList',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/money/list.vue')
  },
  {
    path: '/fundOrderDetail',
    name: 'FundOrderDetail',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/money/orderDetail.vue')
  },
  {
    path: '/fundTransfer',
    name: 'FundTransfer',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/money/transfer.vue')
  },
  {
    path: '/infoComplete',
    name: 'InfoComplete',
	meta: {
		requireAuth: true,
	},
    component:() => import('../views/money/infoComplete.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
	
	let token = localStorage.getItem('token');	
	if(to.meta.requireAuth && (token === null || token === '' || token === 'undefined')) {
		next({
			path: '/'
		})
	}else{
		if(to.path === '/main' && from.path === '/') {
			// 请求公告信息
			Vue.prototype.$request.notice({}).then((data) => {
				// 有公告信息，跳转公告页面展示
				if(data.length != 0) {
					next({
						path: '/notice'
					})
				}
			});
		}
		next();
	}
	next();
})

export default router
