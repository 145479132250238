<template>
  <v-app>
    <v-main>
		<router-view style="height: 100%; max-width: 600px; margin: auto; -webkit-box-shadow: 0 5px 0px rgb(0 0 0 / 50%)"/>
    </v-main>
  </v-app>
</template>

<script>	
	export default {
		name: 'App'
	};
</script>

<style>
	mt-header {
		max-width: 600px;
		margin: auto;
	}
</style>
