// const HOST = "https://ambc.pro/";
// const HOST = "http://localhost:19589";
const HOST = window.location.origin;
const USER_API = HOST + "/api/user/";

const Apis = {
	// 注册
	register: USER_API + 'register',
	// 登录
	login: USER_API + 'login',
	// 发送短信
	sendCode: USER_API + 'sendCode',
	// 发送带国籍的短信
	sendCodeWithCountry: USER_API + 'sendCodeWithCountry',
	// 忘记密码
	forget: USER_API + 'forget',
	// 用户信息
	userInfo: USER_API + 'info',
	// 交易usdt
	trade: USER_API + 'trade',
	// 交易usdm
	tradeUsdm: USER_API + 'tradeUSDM',
	// 互转
	transfer: USER_API + 'transfer',
	// 设置交易密码
	setPayPwd: USER_API + 'setPayPassword',
	// 设置提现地址
	setWithdrawAddress: USER_API + 'setWithdrawAddress',
	// 文件上传
	upload: USER_API + 'common/upload',
	// 资产明细
	history: USER_API + 'history',
	// 身份认证
	submitKyc: USER_API + 'submitKyc',
	// 认证状态
	kycStatus: USER_API + 'kycStatus',
	// 提现
	withdraw: USER_API + 'withdraw',
	// 充值地址
	address: USER_API + 'address',
	// 获取接收人用户信息
	getTargetInfo: USER_API + 'getTargetInfo',
	// 公告
	notice: USER_API + 'notice',
	// 充值未到账
	question: USER_API + 'question',
	// 商品列表
	mallList: USER_API + 'shop/prod_list',
	// 省份列表
	provinceList: USER_API + 'shop/province',
	// 市区列表
	cityList: USER_API + 'shop/cities',
	// 商户列表
	mchList: USER_API + 'shop/mch_list',
	// 下单
	buyGoods: USER_API + 'shop/order',
	// 
	dict: USER_API + 'dict',
	// 获取谷歌验证码
	authCode: USER_API + 'authCode',
	// 设置谷歌验证码
	authPassword: USER_API + 'setAuthPassword',
	// 订单列表
	orderList: USER_API + 'shop/orderList',
	// 订单退单
	orderBack: USER_API + 'shop/back',
	// 购买基金
	buyFund: USER_API + 'fund/buy',
	// 我的基金
	myFundList: USER_API + 'fund/exchange/list',
	// 互转基金
	exchangeFund: USER_API + 'fund/exchange',
	// 基金信息是否完整
	fundComplete: USER_API + 'fund/isComplete',
	// 补全基金信息
	infoComplete: USER_API + 'fund/info/complete'
}
export default Apis
