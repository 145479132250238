import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	token: '',
	gauth: '',
	userInfo: {},
	walletInfo: {}
  },
  mutations: {
	updateToken: (state, token) => {
		state.token = token
		localStorage.setItem('token', token);
	},
	updateUserInfo: (state, infoData) => {
		let userInfo = infoData.info;
		let walletInfo = infoData.wallet;	
		let gauth = infoData.gauth;
		state.userInfo = userInfo;
		state.walletInfo = walletInfo;
		localStorage.setItem('gauth', gauth);
		localStorage.setItem('userInfo', JSON.stringify(userInfo));
		localStorage.setItem('walletInfo', JSON.stringify(walletInfo));
	},
	clearInfo: (state) => {
		state.token = '';
		state.userInfo = {};
		state.walletInfo = {};
		
		localStorage.removeItem("gauth");
		localStorage.removeItem("userInfo");
		localStorage.removeItem("walletInfo");
		localStorage.removeItem("token");
		
		sessionStorage.removeItem("selected");
	}
  },
  actions: {
	
  },
  modules: {
	
  }
})
