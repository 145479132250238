<template>
	<div :style="bg">
		<img class="logo-img" :src="require('../../assets/login/ambc-logo2-color.png')"/>
		<span class="title-text" style="margin-top: 20px;">{{$t('login.welcome')}}</span>
		
		<div class="column-center-content main-content-no-nav">
			<div class="info-content">
				<v-text-field :placeholder="$t('login.phone_hint')" color="success" v-model="account">
					<v-icon style="width: 20px; margin-right: 10px; color:#FF0000;" slot="prepend">
						fa fa-phone
					</v-icon>
				</v-text-field>
				<v-text-field :placeholder="$t('login.pwd_hint')" color="success" v-model="pwd" type="password">
					<v-icon style="width: 20px; margin-right: 10px; color:#FF0000;" slot="prepend" >
						fa fa-lock
					</v-icon>
				</v-text-field>
			</div>
			
			<div style="width: 85%; height: 40px; display: flex; flex-direction: row; justify-content:space-between; align-items: center;">
				<div @click="downloadAndroid" style="font-size: 14px; color: #009245;">
					{{$t('login.download_android')}}
				</div>
				
				<router-link to="/forgetPwd" style="font-size: 14px; color: #009245;">
					{{$t('login.forgetPwd')}}
				</router-link>
			</div>
				
			<div class="btn-content" style="margin-bottom: 0px; margin-top: 15px;">
				<v-btn rounded block @click="languageAction" style="background-color: #009245; color: white; height: 40px;">
					<img src="../../assets/flags/cn.png" style="margin-left: 10px;">
					<img src="../../assets/flags/us.png" style="margin-left: 10px;">
					<img src="../../assets/flags/jp.png" style="margin-left: 10px;">
					<img src="../../assets/flags/kr.png" style="margin-left: 10px;">
					<img src="../../assets/flags/down.png" style="margin-left: 10px;">
				</v-btn>
			</div>

			<div class="btn-content" style="margin-bottom: 0px; margin-top: 15px;">
				<v-btn rounded block @click="goLogin" style="background-color: #009245; color: white; height: 40px;">
					{{$t('login.login')}}
				</v-btn>
			</div>
		
			<router-link v-show="false" to="/register" style="font-size: 14px; color: #009245;  margin-top: 15px;">
				{{$t('login.noAccount')}}
			</router-link>
			
			<mt-popup style="width: 60%; padding: 10px; align-items: center;" v-model="popupVisible" popup-transition="popup-fade">
				<ul style="list-style-type:none; padding-left: 10%">
					<li class="pt-3 pb-3" @click="changeLanguage('cn')">
						{{$t('common.language.cn')}} <img src="../../assets/flags/cn.png" style="margin-left: 10px;">
					</li>
					<li class="pt-3 pb-3" @click="changeLanguage('us')">
						{{$t('common.language.us')}} <img src="../../assets/flags/us.png" style="margin-left: 10px;">
					</li>
					<li class="pt-3 pb-3" @click="changeLanguage('jp')">
						{{$t('common.language.jp')}} <img src="../../assets/flags/jp.png" style="margin-left: 10px;">
					</li>
					<li class="pt-3 pb-3" @click="changeLanguage('kr')">
						{{$t('common.language.kr')}} <img src="../../assets/flags/kr.png" style="margin-left: 10px;">
					</li>
				</ul>
			</mt-popup>
		</div>
	</div>
</template>

<script>
	import "@/styles/common.css"
	import { Indicator } from 'mint-ui';
	import { Toast } from 'mint-ui';
	
	export default {
		name: 'Login',
		data:() => ({
			bg : {
				height: "100vh",
				backgroundImage: "url(" + require("../../assets/login/login-bg.jpeg") + ")",
				backgroundRepeat: "no-repeat center",
				backgroundPosition: "fixed",
				backgroundSize: "100%",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				overflow: "auto"
			},
			checkbox: false,
			account: '',
			pwd: '',
			popupVisible: false,
			pwdTipVisible: false,
			androidUrl: 'https://vvchat.s3.ap-southeast-1.amazonaws.com/AMBAPP.apk'
		}),
		methods: {
			goLogin() {
				
				let account = this.$data.account;
				let pwd = this.$data.pwd;
				
				if(null == account || '' == account) {
					Toast({
						message: this.$i18n.t("login.phone_hint"),
						position: 'bottom'
					});
					return;
				}
				
				if(null == pwd || '' == pwd) {
					Toast({
						message: this.$i18n.t("login.pwd_hint"),
						position: 'bottom'
					});
					return;
				}
				
				this.requestLogin();
			},
			requestLogin() {
				let account = this.$data.account;
				let pwd = this.$data.pwd;
				Indicator.open({
					text: '',
					spinnerType: 'fading-circle'
				});
				let params = {
					account: account,
					password: pwd
				}
				this.$request.login(
					params
				).then((data)=>{
					Indicator.close();
					// 登录完首次进入首页，需要刷新个人信息
					sessionStorage.setItem('refreshInfo', '1');
					// 存储token
					this.$store.commit('updateToken', data.token);
					// 跳转首页
					this.$router.push({
						name: 'Main'
					})
				});
			},
			languageAction() {
				this.$data.popupVisible = true;
			},
			changeLanguage(type) {
				this.$i18n.locale = type;
				this.$data.popupVisible = false;
				localStorage.setItem('language', type);
			},
			downloadAndroid() {
				window.open(this.$data.androidUrl);
			}
		}
	}	
</script>

<style>
	.logo-img {
		width: 100px;
		height: 100px;
		flex: none;
		margin-top: 60px;
		display: block;
	}
</style>
