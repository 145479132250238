import Apis from "./api";
import axios from 'axios';
import store from '@/store';
import router from '@/router';
import { Toast } from 'mint-ui';
import { Indicator } from 'mint-ui';

const METHOD = {
	GET: 'get',
	POST: 'post'
}

/**
 * 网络请求
 * @param method 方法
 * @param url 接口地址
 * @param params 参数
 * @param showError 是否展示错误信息
 * @returns {Promise<any>}
 */
// 错误和失败信息都在这里进行处理，界面中调用的时候只处理正确数据即可
function request(method, url, params, showError, showSuccess) {
	if (showError || showError == undefined){ // 是否展示错误信息
		showError = true;
	}else {
		showError = false;
	}
	
	// console.log("请求地址："+url);
	// console.log("请求参数："+JSON.stringify(params));
	// console.log("请求token："+ localStorage.getItem('token'));
	
	let getParams = params
	let postParams = params
	if (METHOD.POST == method) {
		getParams = {}
	}
	return new Promise((resolve, reject) => {
		axios({
			method: method,
			url: url,
			headers: { 
				'token': localStorage.getItem('token'),
				'Accept-Language': localStorage.getItem('language') ? localStorage.getItem('language') : 'cn'
			},
			data: postParams,
			params: getParams
		}).then((res) => {	
			// console.log("请求结果："+JSON.stringify(res.data));
			if (res.data.code == 200 ) { 
				// 200 -> 请求成功
				resolve(res.data.data);
				if (showSuccess){
					Toast({
						message: res.data.msg,
						position: 'bottom'
					});
				}
				
			}else if (res.data.code == 553 || res.data.code == 554) {
				resolve(res.data);
				if (showSuccess){
					Toast({
						message: res.data.msg,
						position: 'bottom'
					});
				}
			}else if (res.data.code == 401) { 
				// 401 -> token异常
				reject(res.data);
				Indicator.close();
				store.commit('clearInfo');
				
				let toast = Toast({
					message: "登录状态异常，请重新登录",
					position: 'bottom'
				});
				setTimeout(() => {
					toast.close();
					location.href = '/';
				}, 2000);
				
			}else if (res.data.code == 440) { 
				// 440 -> 密码异常，需重置密码
				reject(res.data);
				Indicator.close();
				
				Toast({
					message: res.data.msg,
					position: 'bottom'
				});
				router.push({
					"name": "ForgetPwd"
				})
				
			}else if (res.data.code == 441) { 
				// 441 -> 支付密码异常，需重置支付密码
				reject(res.data);
				Indicator.close();	
				
				Toast({
					message: res.data.msg,
					position: 'bottom'
				});
				router.push({
					"name": "ChangePayPwd"
				})
				
			}else { 
				// 其他情况返回错误信息，根据需要处理
				reject(res.data);
				Indicator.close();
				if (showError){
					Toast({
						message: res.data.msg,
						position: 'bottom'
					});
				}
			}
		}).catch(() => {
			Indicator.close();
			if (showError){
				Toast({
					message: '请求失败，请稍后再试',
					position: 'bottom'
				});
			}
		});
	});
}

/**
 * 图片上传
 * @param url 地址
 * @param params 参数 FormData
 * @param showError 是否展示错误
 * @returns {Promise<any>}
 */
function uploadRequest(url, params, showError) {
	if (showError || showError == undefined){
		showError = true;
	}else {
		showError = false;
	}
	let config = {
		headers: { "Content-Type": "multipart/form-data", 'token': localStorage.getItem('token') },
	}
	return new Promise((resolve, reject) => {
		axios.post(url,params,config).then((res) => {
			console.log("请求结果："+JSON.stringify(res.data)); 
			if (res.data.code == 200) {
				resolve(res.data.url);
			}else if (res.data.code == 401) { 
				// 401 -> token异常
				reject(res.data);
				Indicator.close();
				store.commit('clearInfo');
				
				let toast = Toast({
					message: "登录状态异常，请重新登录",
					position: 'bottom'
				});
				setTimeout(() => {
					toast.close();
					location.href = '/';
				}, 2000);
				
			}else {
				reject(res.data);
				if (showError){
					Toast({
						message: res.data.msg,
						position: 'bottom'
					});
				}
			}
		}).catch(() => {
			if (showError){
				Toast({
					message: '请求失败，请稍后再试',
					position: 'bottom'
				});
			}
		});
	});
}

function get(url, params, showError) {
	return request(METHOD.GET, url, params, showError);
}

// function getUrl(url, params, showError) {
// 	return request(METHOD.GET, url+"/"+params, {}, showError);
// }

function post(url, params, showError) {
	return request(METHOD.POST, url, params, showError);
}

const API = {
	// 注册
	register: (params) => post(Apis.register, params, true, true),
	// 登录
	login: (params) => post(Apis.login, params, true, true),
	// 发送短信
	sendCode: (params) => get(Apis.sendCode, params, true, true),
	// 发送带国籍的短信
	sendCodeWithCountry: (params) => get(Apis.sendCodeWithCountry, params, true, true),
	// 忘记密码
	forget: (params) => post(Apis.forget, params, true, true),
	// 用户信息
	userInfo: (params) => get(Apis.userInfo, params, true, false),
	// 交易usdt
	trade: (params) => post(Apis.trade, params, true, true),
	// 交易usdm
	tradeUsdm: (params) => post(Apis.tradeUsdm, params, true, true),
	// 互转
	transfer: (params) => post(Apis.transfer, params, true, true),
	// 设置交易密码
	setPayPwd: (params) => post(Apis.setPayPwd, params, true, true),
	// 设置提现地址
	setWithdrawAddress: (params) => post(Apis.setWithdrawAddress, params, true, true),
	// 文件上传
	upload: (params) => uploadRequest(Apis.upload, params, true),
	// 资产明细
	history: (params) => get(Apis.history, params, true, true),
	// 实名认证
	submitKyc: (params) => post(Apis.submitKyc, params, true, true),
	// 认证状态
	kycStatus: (params) => post(Apis.kycStatus, params, true, true),
	// 提现
	withdraw: (params) => post(Apis.withdraw, params, true, true),
	// 充值地址
	address: (params) => get(Apis.address, params, true, true),
	// 获取接收人用户信息
	getTargetInfo: (params) => get(Apis.getTargetInfo, params, true, false),
	// 公告
	notice: (params) => get(Apis.notice, params, true, true),
	// 充值未到账
	question: (params) => get(Apis.question, params, true, false),
	// 商品列表
	mallList: (params) => get(Apis.mallList, params, true, true),
	// 省份列表
	provinceList: (params) => get(Apis.provinceList, params, true, true),
	// 城市列表
	cityList: (params) => get(Apis.cityList, params, true, true),
	// 商户列表
	mchList: (params) => get(Apis.mchList, params, true, true),
	// 下单
	buyGoods: (params) => post(Apis.buyGoods, params, true, true),
	// 字典
	dict: (params) => get(Apis.dict, params, true, true),
	// 获取google验证码
	authCode: (params) => get(Apis.authCode, params, true, true),
	// 设置google验证码
	authPassword: (params) => post(Apis.authPassword, params, true, true),
	// 查询订单列表
	orderList: (params) => post(Apis.orderList, params, true, true),
	// 查询订单列表
	orderBack: (params) => get(Apis.orderBack, params, true, true),
	// 购买基金
	buyFund: (params) => post(Apis.buyFund, params, true, true),
	// 我的基金列表
	myFundList: (params) => get(Apis.myFundList, params, true, true),
	// 互转基金
	exchangeFund: (params) => post(Apis.exchangeFund, params, true, true),
	// 基金信息是否完整
	fundComplete: (params) => get(Apis.fundComplete, params, true, true),
	// 补全基金信息
	infoComplete: (params) => post(Apis.infoComplete, params, true, true),
}

function install(Vue) {
	Vue.prototype.$request = API;
}

export default install